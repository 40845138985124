import {Controller} from "stimulus"
import $ from 'jquery';
import {checkSessionVar, setSessionVar} from "./sessionStorage_controller";



export default class extends Controller {

    connect() {

    }

    previewImg(e){
        let archivos = e.target.files;
        let preview = $('#'+e.target.dataset.preview);
        let button = document.createElement('button')
        button.setAttribute('type','button');
        button.classList.add('btn');
        button.classList.add('btn-danger');
        button.classList.add('btn-delete');
        button.innerText = 'X'
        button.addEventListener('click', function (event) {
            e.target.value = '';
            preview[0].src = '/nopic_transparent.png'
        })
        preview[0].src = URL.createObjectURL(archivos[0]);
        preview[0].parentNode.appendChild(button);


    }
}