import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar, checkSessionVar, checkSessionVarWithData} from './sessionStorage_controller'
import {get_popup} from './popup_controller'
import {set_locale} from './localize_controller'


import {preferences} from './preferences_controller'
import {catalog} from './catalogs_controller'
import Swal from 'sweetalert2/dist/sweetalert2'
import {blockBody, unblockBody} from './blockUI_controller'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import 'slick-carousel/slick/slick.min'
import {session} from "@hotwired/turbo";

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim ());
}

let aleatori_behavior = () => {
    // var myArray = ['backInLeft', 'backInRight', 'backInUp', 'bounceIn', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'fadeInLeftBig', 'fadeInRightBig', 'fadeInUpBig'];
    var myArray = ['backInUp', 'bounceIn', 'bounceInUp'];
    var rand = Math.floor (Math.random () * myArray.length);
    return myArray[rand];
}


require ("select2/dist/css/select2")
require ("select2-bootstrap-theme/dist/select2-bootstrap")


let prev = 0;
let next = 0;

let products_for_map = () => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `${location.origin}/${sessionStorage.getItem ('locale')}/admin/product_types/json`,
        data: {},
        type: 'GET',
        success: function (data) {
            if (data.rows.length > 0) {
                let types = ''
                data.rows.map ((element) => {
                    if (parseInt (element.count) > 0) {
                        types += `<li style="cursor: pointer; user-select: none;">
                                <label for="input_map_${element.id}" class="d-flex justify-content-start", style="gap:0 25px;">
                                <input id="input_map_${element.id}" type="checkbox" value="${element.id}" class="checkbox_map_filter">
                                <span class="d-flex justify-content-start" style="gap:0 5px"><span class="icon-li">${element.icon}</span> ${element.name}</span></li></label>`;
                    }
                });

                resolve (types);
            }
        }
    })
})

let staticSection = (context) => new Promise ((resolve, reject) => {
    let section_conatiner = $ ('#section_container');
    let footer_container = $ ('#footer_section');
    let html = '<div data-controller="word wowjs">';
    let footer = '';
    let section_show_html = $ ('#sections_show');
    let sections_show_in = '';
    $.ajax ({
        url: `/${sessionStorage.getItem ('locale')}/admin/sections.json`,
        data: {format: "json"},
        type: "get",
        success: function (sections) {
            let count_sections = 0;
            sections.map ((section) => {
                let active_section = (count_sections === 0) ? 'active' : '';
                if (section_conatiner[0] !== undefined) {
                    if (section.destination === 'body') {
                        if (section.show_in_bar) {
                            sections_show_in += `
                                        <li class="rd-nav-item ${active_section}"><a id="to_section_${section.id}" class="rd-nav-link" href="#section_${section.id}_nav"> ${section.name_in_bar}</a></li>
                                        `
                            count_sections += 1;
                        }

                        let img = section.thumbnails_url || section.image_url;
                        html += context.tpl_section (section.id, section.access_url, img, section.helpertext, section.name, section.descriptions.body, section.backgroud, section.typesection);
                    }
                }
                // if (footer_container[0] !== undefined) {
                //     if (section.destination === 'footer') {
                //         footer += context.tpl_section_footer (section.id, section.access_url, section.name, section.typesection);
                //     }
                // }
            });

            if (section_show_html.length > 0) {
                section_show_html[0].innerHTML = sections_show_in;
            }

            if (section_conatiner[0] !== undefined) {
                html += '</div>';
                section_conatiner[0].innerHTML = html;
            }

            if (footer_container[0] !== undefined) {
                footer_container.html (footer);
            }


            resolve ({
                next: true
            })

        }
    })
});
let staticPage = (context) => new Promise ((resolve, reject) => {
    let footer_container = $ ('#footer_section');
    let footer = '';

    $.ajax ({
        url: `/${sessionStorage.getItem ('locale')}/admin/pages.json`,
        data: {format: "json"},
        type: "get",
        success: function (pages) {
            let html = '';
            let count_page = {};

            pages.map ((page) => {

                if (page.show) {
                    let url = '';
                    let section_body = $ ('#section_' + page.section.id);

                    if (count_page.hasOwnProperty ('section_' + page.section.id)) {
                        count_page['section_' + page.section.id] += 1;
                    } else {
                        count_page['section_' + page.section.id] = 1
                    }


                    if (page.isExternaUrl) {
                        url = page.access_url;
                    } else {
                        if (page.access_url === null || page.access_url === "" || page.access_url === undefined) {
                            url = 'javascript:;';
                        } else {
                            url = location.origin + `/${sessionStorage.getItem ('locale')}/pages/ver/` + page.access_url;
                        }
                    }

                    if (page.section.destination === 'body') {
                        let image = page.thumbnails_url || page.image_url;
                        let icon = '';
                        let description = '';
                        if (page.icon !== null) {
                            if (!page.icon.isEmpty ()) {
                                if (page.icon.includes ('fa')) {
                                    icon = `fa ${page.icon}`;
                                } else {
                                    icon = page.icon;
                                }
                            }
                        }

                        if (page.short_description !== null) {
                            description = page.short_description+'...';
                        }

                        if (page.section.typesection === 'presentation-time-line') {
                            description = page.contents.body
                        }

                        if (section_body !== undefined) {
                            section_body.append (context.tpl_page (page.id, url, page.isExternaUrl, page.name, description, page.section.typesection, image, icon, count_page['section_' + page.section.id]));
                        }
                    }

                    if (page.section.destination === 'footer') {
                        if (footer_container[0] !== undefined) {

                                footer += context.tpl_page_footer (page.id, url, page.isExternaUrl, page.name)

                        }
                    }

                }
            })

            if (footer_container[0] !== undefined) {
                footer_container.html (footer);
            }

            resolve ({
                next: true
            });
        }
    })
})


export default class extends Controller {

    initialize () {
        set_locale ().then (
            locale => {
                const btn_assistant_line = $ ('#btn_assistant_line');
                if (location.pathname.includes ('help_me')) {
                    btn_assistant_line[0].style.display = 'none';
                }
                if (sessionStorage.hasOwnProperty ('need_clean')) {
                    if (sessionStorage.getItem ('need_clean') === "true" && sessionStorage.getItem ('inPayProccess') === "true" && location.pathname.includes ("/payment/return")) {
                        setSessionVar ('', '', 'clear', false);
                    }
                }

                const context = this;
                staticSection (context).then (
                    result => {
                        if (result.next) {
                            staticPage (context).then (
                                page_result => {
                                    if (page_result.next) {
                                        // $('.slick_catalog').slick({
                                        //     speed: 300,
                                        //     slidesToShow: 4,
                                        //     slidesToScroll: 2,
                                        //     rows: 1,
                                        //     infinite: true,
                                        //     autoplay: true,
                                        //     autoplaySpeed: 3000,
                                        //     responsive: [
                                        //         {
                                        //             breakpoint: 1800,
                                        //             settings: {
                                        //                 slidesToShow: 4,
                                        //                 slidesToScroll: 2,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 1280,
                                        //             settings: {
                                        //                 slidesToShow: 3,
                                        //                 slidesToScroll: 3,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 900,
                                        //             settings: {
                                        //                 slidesToShow: 2,
                                        //                 slidesToScroll: 2,
                                        //             }
                                        //         },
                                        //         {
                                        //             breakpoint: 700,
                                        //             settings: {
                                        //                 slidesToShow: 1,
                                        //                 slidesToScroll: 1,
                                        //                 rows: 1,
                                        //             }
                                        //         },
                                        //
                                        //         // You can unslick at a given breakpoint now by adding:
                                        //         // settings: "unslick"
                                        //         // instead of a settings object
                                        //     ]
                                        // })
                                        $ ('.slick_notice').slick ({
                                            infinite: false,
                                            speed: 300,
                                            slidesToShow: 4,
                                            slidesToScroll: 1,
                                            rows: 1,
                                            responsive: [
                                                {
                                                    breakpoint: 1300,
                                                    settings: {
                                                        slidesToShow: 3,
                                                        slidesToScroll: 1,
                                                        infinite: true,
                                                    }
                                                },
                                                {
                                                    breakpoint: 1028,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 800,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        });
                                        $ ('.slick-three-column').slick ({
                                            infinite: false,
                                            speed: 300,
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            rows: 1,
                                            responsive: [
                                                {
                                                    breakpoint: 1028,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 700,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        });
                                        $ ('.slick-five-column').slick ({
                                            infinite: true,
                                            speed: 500,
                                            slidesToShow: 3,
                                            autoplay: true,
                                            autoplaySpeed: 3000,
                                            rows: 1,
                                            responsive: [
                                                /*{
                                                    breakpoint: 1440,
                                                    settings: {
                                                        slidesToShow: 4,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },*/
                                                {
                                                    breakpoint: 1350,
                                                    settings: {
                                                        slidesToShow: 3,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 900,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },
                                                {
                                                    breakpoint: 600,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        rows: 1,
                                                        infinite: true,
                                                        autoplay: true,
                                                        autoplaySpeed: 3000
                                                    }
                                                },

                                                // You can unslick at a given breakpoint now by adding:
                                                // settings: "unslick"
                                                // instead of a settings object
                                            ]
                                        })

                                        unblockBody ();

                                    }
                                }
                            )
                        }
                    }
                )
            })
        $ ('#reviews-wrapper').slick ({
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        rows: 1,
                    }
                }, {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        rows: 1,
                    }
                }

                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        })
    }

    connect () {
    }

    prev (e) {
        let element = e.target.dataset.target;
        if (prev < 0) {
            next -= (window.innerWidth - 20);
            prev += (window.innerWidth - 10);
            $ ('#' + element)[0].style.left = `${prev}px`;
        }
    }

    next (e) {
        next += (window.innerWidth - 20);
        prev -= (window.innerWidth - 10);
        let element = e.target.dataset.target;
        $ ('#' + element)[0].style.left = `${-next}px`;
    }

    tpl_section (id, url, picture, helpertext, name, description, background, type) {
        let description_transform = '';
        if (description !== null) {
            description_transform = description.slice (0, 350);
        } else {
            description_transform = '';
        }
        description_transform = description_transform.split("<div>").join('');
        description_transform = description_transform.split("</div>").join('')
        description = description_transform+'...';

        console.log(type);

        // let html = `<div id="section_${id}_nav" class="row no-print ${background} wow bounceInUp">`;
        let html = ``;
        switch (type) {
            case 'presentation_with_image_left':
                html += `
                <div class="col-md-12 section">
                  <div class="presentation-with-image-left">`;
                if (picture !== undefined) {
                    html += `<div class="image-container">
                           <div class="image-divider-mobile"></div>
                           <img src="${picture}" alt="${name}">
                        </div>`;
                }
                html += `
                    <div class="info-container">
                      <div class="helper-text">
                       ${helpertext}
                      </div>
                      <div class="title-text last-word-change">
                        ${name}
                      </div>
                      <div class="divider"></div>
                      <div class="description-text">
                        ${description}
                      </div>
                       <div class="button_action">
                                    <button onclick="location.href = '/${sessionStorage.getItem ('locale')}/sections/ver/${url}'"> ${I18n.t ('global.main.read_more')}</button>
                        </div>
                    </div>

                    <div class="page-wrapper">
                      <div class="divider-vertical"></div>
                      <div class="">
                        <ul id="section_${id}" class="pages"></ul>
                      </div>
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_5_columns':
                html += `
                <div class="col-md-12">
                  <div class="presentation-with-pages-by-column">
                    <div class="presentation-title">
                      <div class="presentation-text last-word-change"> ${name}</div>
                      <div class="presentation-helper"> ${helpertext}</div>
                       <div class="divider"></div>
                    </div>
                   
                    <div id="section_${id}" class="presentation-body slick-five-column">
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation_with_image_3_columns':
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation_with_image_3_columns">
                    <div class="presentation-title">
                      <div class="presentation-text last-word-change" > ${name}</div>
                      <div class="divider"></div>
                    </div>
                    
                    <div id="section_${id}" class="presentation-body slick-three-column">
            
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-whit-image-right':
                html += `<span id="section_${id}_textname" class="ocultar">${name}</span>`;

                    html += `
                <div class="col-md-12">
                  <div class="presentation-whit-image-right">
                    <div class="page-container">
                        <div class="content-presentation">
                         <div class="title-presentation">
                            <div class="page-header last-word-change" data-target="text_section_${id}" data-source="section_${id}_textname">${name}</div>
                            <div class="page-helper-text">${helpertext}</div>
                            <div class="divider"></div>
                        </div>
                         <div class="page-wrapper">
                            <ul id="section_${id}" class="page-list">
                
                            </ul>
                          </div> 
                        </div>
                    </div>
                  </div>
                </div>  `;

                break;
            case 'presentation_with_image_2_columns':
                html += `
                 <div class="col-md-12 section">
                  <div class="presentation_with_image_2_columns">
                    <div id="section_${id}" class="page-container">
            
                    </div>
                  </div>
                </div>
                `;
                break;
            case 'presentation-paralax-with-3-columns':
                html += `
                 <div class="col-md-12 ">
                  <div class="presentation-paralax-with-3-columns">
                    <div class="backgroud-paralax" style="background-image: url('${picture}'); background-size: cover; height: 100%;">
                     
                      <div class="presentation-wrapper">
                        <div class="presentation-helper-text">${helpertext}</div>
                        <div class="presentation-header">
                          ${name}
                        </div>
                        <div class="presentation-description">
                          <div class="description-text">
                            ${description}
                          </div>
                        </div>
                        <div id="section_${id}" class="presentation-pages"></div>
                      </div>
                    </div>          
                  </div>       
                </div>
                `;
                break;
            case 'presentation-with-notice':
                html += `
                   <div class="col-md-12 section">
                    <div class="presentation-with-notice">
                      <div class="notice-container">
                      <div class="notice-header">
                       <div class="notice-title last-word-change">${name}</div>
                        <div class="notice-divider"></div>
                        </div>
                        <div class="notice-body">
                         <div id="section_${id}" class="notice-list slick_notice"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                        <div class="container-fluid">
                          <div class="presentation-comercial-client">
                              <div class="presentation-title col-12 col-md-12">
                                <div class="presentation-text last-word-change" data-controller="word">
                                  ${name}
                                </div>
                                <div class="mb-3">
                                ${helpertext}
                                </div>
                                <div class="divider"></div>
                              </div>
                              <div id="section_${id}" class="presentation-body row align-items-end"></div>
                            </div>
                       
                        
                        </div>
                `;
                break;
            case 'presentation-with-map':
                html += `
                        <div class="container-fluid">
                          <div class="presentation-with-map" id="data_map" data-icon="/map/pin.png">
<!--                            <div class="flex-map"">-->
                              <div class="left">
<!--                                <div id="section_${id}" class="presentation-title">-->
                                  <div class="presentation-text last-word-change">${name}</div>
                                  <div class="divider"></div>
                                  <div class="description">${helpertext}</div>
<!--                                </div>-->
                              </div>
                              <div class="right">
                                <div id="overlay_map_load" class="overlay_map_load"></div>
                                    <div class="for_map" data-controller="map">
<!--                                      <div class="action_map_buttons">-->
<!--                                      <div class="box_action">-->
<!--                                         <button id="button_filter_map_${id}" class="button_map" type="button" data-action="setValue#active_desactive_box_map" data-target="box_options_${id}">-->
<!--                                           <span class="fa fa-filter" data-target="box_options_${id}"></span>-->
<!--                                         </button>-->
<!--                                         <div id="box_options_${id}" class="box_options">-->
<!--                                           <ul id="map_li_options${id}">-->
<!--                                            -->
<!--                                            </ul>-->
<!--                                            <button type="button" class="btn_filter_map" data-action="map#refreshMap" data-close="box_options_${id}" data-target="checkbox_map_filter">-->
<!--                                              Filtrar-->
<!--                                            </button>-->
<!--                                         </div>-->
<!--                                        </div>-->
<!--                                        -->
<!--                                       </div>-->
                                      <div id="map" ></div>
                                    </div>
                                </div>
                              </div>
<!--                            </div>-->
                          </div>
                        </div>
                `;

                products_for_map ().then (
                    types => {
                        let li_opt = $ (`#map_li_options${id}`);
                        if (types.isEmpty ()) {
                            $ (`#button_filter_map_${id}`).addClass ('ocultar');
                        } else {
                            li_opt.html (types);
                        }

                    }
                )
                break;
            case 'marquee':
                html += `
                     <div class="container-fluid mt-3 mb-5 pb-5" data-controller="marquee" id="section_${id}">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="marquee" data-target='marquee_state' data-action="mouseenter->marquee#marquee mouseleave->marquee#marquee">
                                <div id='marquee_state' class="content">
                        
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  `;
                break;
            case 'catalog':
                html += `
                     <div class="container-fluid wow bounceInUp">
                        <div class="row mt-2">
                          <div class="col-12" >
                            <div class="catalog" id="section_${id}">
                              <h2>${helpertext}</h2>
                              <div id="catalogo_container" class="catalog-body slick_catalog" data-controller="catalogs" data-show="true">
                    
                              </div>
                              <div id="more_catalog_result" class="more-results">
                                <button id="btn_more_result_catalog" type="button" data-controller="catalogs" data-action="catalogs#get_more_data">${I18n.t ('global.main.view_more')}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  `;
                break;
            case 'presentation-time-line':
                html += `
                <div class="presentation-time-line">
                  <div class=" mt-5 mb-5 pt-5">
                    <div class="row">
                      <div class="col-md-8">
                        <h2>${name}</h2>
                        <ul class="timeline" id="section_${id}">
                          
                        </ul>
                      </div>
                      <div class="col-md-4 page-image-container" >
                         <img src="${picture}" alt="${name}">
                      </div>
                    </div>
                  </div>
                </div>
                `
                break;
            case 'faqs':
                html += `
                 <div class="container-fluid">
                  <div class="row my-5">
                      <div class="col-12 ">
                        <div id="faqs"  class="presentation-faq">
                          <h2>${name}</h2>
                          <div class="accordion mt-3" id="section_${id}" data-controller="faqs"></div>
                        </div>
                      </div>
                  </div>
                </div>
                `;
                break;
        }

        html += `</div>`;

        return html;
    }

    tpl_section_footer (id, url, name, type) {
        let html = ``;

        switch (type) {
            case 'footer':
                html += `
                   <div class="footer-section">
                    <div class="section-title">
                      ${name}
                    </div>
                    <div id="section_${id}" class="section-page-wrapper">
                    </div>
                  </div>
                `;
                break;
        }

        return html;
    }

    tpl_page (id, url, isExternal, name, description, type, picture, icon, index) {
        let des = description || ' ';
        let html = ``;

        switch (type) {
            case 'presentation_slider':
                html = `
                   <div class="swiper-slide" data-slide-bg="${picture}">
        <div class="swiper-slide-caption section-md">
          <div class="container">
            <h1 data-caption-animate="fadeInLeft" data-caption-delay="0">${name}</h1>
            <p class="text-width-large" data-caption-animate="fadeInRight" data-caption-delay="100">${description}</p>
            <a class="button button-primary button-ujarak" href="#modalCta" data-toggle="modal" data-caption-animate="fadeInUp" data-caption-delay="200">Get
              in touch</a>
          </div>
        </div>
      </div>
     
                   `;
                break;
            case 'presentation_with_image_left':
                html = `
                    <li class="page-section">
                    <div class="page-icon">
                        <span class="${icon}"></span>
                    </div>
                    <div class="page-container">
                        <div class="page-title">
                            ${name}
                        </div>
                        <div class="divider"></div>
                        <div class="page-body">
                           ${des}
                        </div>
                    </div>
                </li>`;
                break;
            case 'presentation_with_image_5_columns':
                html = ` <div class="">
                    <div class="page-card">
                      <div class="page-slider">
                        <div class="page-card-image">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="page-card-body">
                            <div class="page-title">
                                ${name}
                            </div>
                            <div class="page-divider"></div>
                            <div class="page-helper">
                                ${description}
                            </div>
                            <div class="page-buttons">
                                 <button onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                            </div>
                        </div>
                        </div>
                   
                  </div>
                </div>
                </div>`;
                break;
            case 'presentation_with_image_3_columns':
                html = ` 
                 <div class="">
                 <div class="page-card">
                                  <div class="page-slider">
                                    <div class="page-card-image">
                                      <img src="${picture}" alt="${name}" onclick="location.href = '${url}'">
                                    </div>
                                    <div class="page-title">
                                      ${name}
                                    </div>
                                    <div class="page-divider"></div>
                                  </div>
                                </div>
                </div>
                 `;
                break;
            case 'presentation-whit-image-right':
                html = ` <li>
                    <div class="page-icon">
                      <span class="${icon}"></span>
                    </div>
                    <div class="page-container">
                      <div class="page-title">${name}</div>
                      <div class="page-description">${description}</div>
                      <div class="divider-page"></div>
                      <div class="page-button">
                        <a href="${url}">${I18n.t ('global.main.read_more')}</a>
                      </div>
                    </div>
                  </li>`;
                break;
            case 'presentation_with_image_2_columns':
                let text = des.slice (0, 250);
                html = ` <div class="page">
                <div class="page-image-container">
                <div class="overlay"></div>
                  <img src="${picture}" alt="${name}">
                </div>
                <div class="page-body-container">
                  <div class="body-header">${name}</div>
                  <div class="body-text">${text}...</div>
                  <div class="body-button">
                    <button onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                  </div>
                </div>
              </div>`;
                break;
            case 'presentation-paralax-with-3-columns':
                html = `
                  <div class="page">
                    <button onclick="location.href = '${url}'">${name}</button>
                  </div>
                `;
                break;
            case 'presentation-with-notice':
                html += `
                    <div class="">
                     <div class="notice">
                        <div class="n-picture">
                          <img src="${picture}" alt="${name}">
                        </div>
                        <div class="n-divider"></div>
                        <div class="n-wrapper">
                        <div class="n-title">
                          ${name}
                        </div>               
                        <div class="n-body">
                          ${des}
                        </div>
                        <div class="n-footer">
                          <button type="button" onclick="location.href = '${url}'">${I18n.t ('global.main.read_more')}</button>
                        </div>   
                        </div>
                                     
                      </div>
                    </div>
                 
                `;
                break;
            case 'presentation-comercial-client':
                html += `
                               <div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                <div class="card-comercial">
                                  <div class="image-container">
                                    <img src="${picture}" alt="${name}">
                                  </div>
                                  <div class="image-text d-flex justify-content-center">
                    
                                    <div class="divider"></div>
                                  </div>
                                </div>
                              </div>
                                 `;
                break;
            case 'presentation-time-line':
                html += `
                        <li data-step="${index}">
                            <a target="_blank" href="javscript:;">${name}</a>
                            <a href="javascript:;" class="float-right"></a>
                            <p>${description}</p>
                        </li>
`
                break;
        }


        return html;
    }


    tpl_page_footer (id, url, isExternal = false, name) {
        let html = '';
        if (isExternal) {
            html = `<li> <a href="${url}" target="_blank">${name}</a></li>`;
        } else {
            html = `<li> <a href="${url}" >${name}</a></li> `;
        }
        return html
    }

    print (e) {
        set_locale ().then (
            locale => {
                location.href = location.origin + `/${locale.locale}/histories/${e.target.dataset.history}.pdf`

            }
        )
    }

    show_hide_element (e) {
        let hide = $ ('#' + e.target.dataset.hide);
        let show = $ ('#' + e.target.dataset.show);

        hide.addClass ('ocultar');
        show.removeClass ('ocultar');
    }

    assistan_line (e) {
        let url = location.origin + `/${sessionStorage.getItem ('locale')}/help_me`;
        if (localStorage.hasOwnProperty ('chat_room')) {
            url = location.origin + `/${sessionStorage.getItem ('locale')}/help_me?id=` + sessionStorage.getItem ('chat_room');
        }

        location.href = url;
    }

    active_desactive_box_map (e) {
        let element = e.target.dataset;
        if (!element.hasOwnProperty ('id')) {
            element = e.target.parentNode.dataset;
        }
        $ (`#${element.target}`)[0].classList.toggle ('active');
    }

    saltarA (e) {
        let element = $ (e.target.dataset.target);
        let top = parseFloat (element.offset ().top) - 75;
        $ ("html, body").animate ({scrollTop: top}, 1000);

        let button = $ ('button[data-target="#navbarText"]');
        button.trigger ('click');
    }


    clear_session (e) {
        alert ('Vamos a limpiar la section');
        setSessionVar ('', '', 'clear', false).then (
            clear => {
                location.href = location.origin
            }
        )
    }


}