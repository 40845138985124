import {Controller} from "stimulus"
import $ from 'jquery';
import * as bootstrap from "bootstrap";


import popper from '@popperjs/core'

let verify_token_recovery = (token) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: `${location.origin}/password_reset/${token}`,
        type: 'get',
        data: {
            format: 'json'
        },
        beforeSend: function () {

        },
        success: function (data) {
            // console.log (data);
            if (data.success && data.status === 1) {
                resolve ({
                    next: true,
                    email: data.user
                })
            } else {
                toastr.error (data.message, data.code)
                resolve ({
                    next: false
                })
            }
        }
    })
})


export default class extends Controller {

    connect () {
        let modal_recovery = $ ('#recovery_pasword_modal');
        let options = {
            show: true,
            focus: true,
            keyboard: false,
            backdrop: 'static'

        }
        let url = location.href;
        let url_recovery = url.split ('password_reset=');


        if (url_recovery.length > 1)  {
            if (modal_recovery.length > 0) {
                verify_token_recovery (url_recovery[1]).then (
                    verify => {
                        if (verify.next) {
                            modal_recovery.modal (options);

                            // ACCION PARA CUANDO SE VA A ENVIAR EL FORMULARIO
                            let recovery = $ ('#recovery_submit');

                            recovery.on ('click', function () {
                                let form = $ (`#recovery_user_password_form`);
                                let authenticity_token = form.find ('input[name="authenticity_token"]');
                                let password = form.find ('input#password_recovery');
                                let password_confirmation = form.find ('input#password_recovery_confirmation');
                                let error = false;

                                console.log(password);
                                console.log(password.parent());
                                if (password.val ().isEmpty ()) {
                                    password.addClass ('is-invalid');
                                    password.parent().addClass('has-error')
                                    error = true;
                                }
                                if (password_confirmation.val ().isEmpty ()) {
                                    password_confirmation.addClass ('is-invalid');
                                    password_confirmation.parent().addClass('has-error')
                                    error = true;
                                }

                                if (!error) {
                                    $.ajax ({
                                        url: form[0].action,
                                        data: {
                                            "_method": "patch",
                                            "authenticity_token": authenticity_token.val(),
                                            "email": verify.email,
                                            "user": {
                                                "password": password.val(),
                                                "password_confirmation": password_confirmation.val()
                                            },
                                            "locale": "es",
                                            "format": 'json'
                                        },
                                        type: form[0].method,
                                        beforeSend: function () {

                                        },
                                        success: function (data) {
                                            if(data.success){
                                                toastr.success(data.message, data.code);
                                                setTimeout(()=>{
                                                    location.href = `${location.origin}/${sessionStorage.getItem('locale')}`;
                                                },1000)
                                            }else{
                                                toastr.error(data.message, data.code);
                                            }
                                        }
                                    })
                                }

                            })
                        }
                    }
                )
            }
        }

    }
}