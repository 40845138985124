import Rails from "@rails/ujs"
import {Turbo} from "@hotwired/turbo-rails"
import "channels"
Rails.start ()
Turbo.session.drive = false
Turbo.start ()
import "wowjs/css/libs/animate"

import "controllers"

import toastr from 'toastr'
import 'toastr/toastr.scss'
window.toastr = toastr

//= require i18n
//= require i18n.js
//= require i18n/translations
import I18n from 'i18n-js'

require ('../i18n/translation')
window.I18n = I18n


$ (document).on ('turbolinks:load', () => {
    let locale = $ ('body').data ('locale');
    I18n.defaultLocale = locale
    I18n.locale = locale
})

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim ());
}
