import {Controller} from "stimulus"
import $ from 'jquery';
import toastr from 'toastr/toastr'
import bootbox from 'bootbox/bootbox';
import 'toastr/toastr.scss'


import popper from '@popperjs/core'

let obtenerIdCheckDatatable = () => {
    let array = []
    let input = $('#table').find('input[type="checkbox"]');
    input.map(elem => {
        let check = $(input[elem]);
        if (check.is(':checked')) {
            if (check.val() !== 'on') {
                array.push(check.val());
            }
        }

    })
    return array;
}

let contar_caracteres = (max,counter,elem) => {

        //Numero de caracteres permitidos
        var total=max;
        var valor=document.getElementById(elem);
        var respuesta=document.getElementById(counter);
        var cantidad=valor.value.length;

        if(parseInt(cantidad) >= parseInt(total)){
           valor.value = valor.value.slice(0,parseInt(max));
        }

        setTimeout(function(e){
            document.getElementById(counter).innerHTML = cantidad +'/'+ total;
            if(cantidad >= total){
                respuesta.style.background = "#d43f34";
            }else{
                respuesta.style.background = "#6a6866";
            }
        },10);

}



export default class extends Controller {
    initialize(){
        $('[data-toggle="tooltip"]').tooltip()

        //PAGES
        if(location.pathname.includes('admin/pages')) {
            let external_url = $('#page_isExternaUrl');
            if(external_url !== undefined && external_url !== null){
                let fields = document.getElementById('fields');
                let thumbnails_ni = document.getElementById('thumbnails-no-image');
                let thumbnails_image = document.getElementById('thumbnails-image');

                if(external_url.is(':checked')){
                    fields.style.display = 'none';
                    thumbnails_ni.style.display = 'block';
                    thumbnails_image.style.display = 'none';
                }
            }

            let page_section_id = $('#page_section_id');
            if(page_section_id.length > 0 || true){
                if(page_section_id.val() !== undefined){
                    $.ajax({
                        url: `${location.origin}/admin/sections/${page_section_id.val()}.json`,
                        data: { format: 'json'},
                        type: 'get',
                        success: function (data) {
                            // console.log(data);
                            if((data.typesection === 'presentation_with_image_left' || data.typesection === 'presentation-whit-image-right') && !external_url.is(':checked')){

                                document.getElementById('page_icon').style.display = 'inline'
                            }
                        }
                    })
                }
            }

        }


        //Notification PUSH
        if(location.pathname.includes('admin/notification_pushes')){
            if(location.pathname.includes('new')){
                let check_box_all = document.getElementById("notification_push_notify_all");
                check_box_all.checked = true;
            }

        }

        //OFICINAS EDIT MAP
        if(location.pathname.includes('admin/offices/')){
            let values = $('#product_selected');
            let product_type= $('#product_type');
            product_type.select2({
                theme: 'bootstrap',
                placeholder: 'Seleccione 1 o varios tipos de productos',
                multiple: true
            })

            if(values.length > 0){
                values = values[0].dataset.products;
                if(!values.isEmpty()){
                    values = values.split(',');
                }

                product_type.val(values);
                product_type.trigger('change');
            }
        }


    }

    connect() {
        $(".sidebar-dropdown > a").click(function () {
            $(".sidebar-submenu").slideUp(200);
            if (
                $(this)
                    .parent()
                    .hasClass("active")
            ) {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .parent()
                    .removeClass("active");
            } else {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .next(".sidebar-submenu")
                    .slideDown(200);
                $(this)
                    .parent()
                    .addClass("active");
            }
        });

        $("#close-sidebar").click(function (e) {
            $(".pages-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function () {
            $(".pages-wrapper").addClass("toggled");
        });

        //    CAMBIOS EN LOS SELECT2 DE LA ADMINISTRACION
        $('#preference_behavior').on('change', function (e) {
            let valor = $(e.target).select2('data')[0].text;
            let elem_unblock = $('#type_' + valor);
            if (elem_unblock !== undefined) {
                elem_unblock.removeClass('ocultar').on('change', function (e) {
                    let other_valor = $(e.target).select2('data')[0].text;
                    let other_elemt_unblock = $('#type_' + other_valor);
                    if (other_elemt_unblock !== undefined) {
                        other_elemt_unblock.removeClass('ocultar');
                    }
                });
            }

        });

         //PREFERENCIAS

        $('#preference_has_slider').on('change', function (e){
            let container_group_slider = $('#container-group-slider');
            let group_slider_clone = $('#group_slider_clone');
            if(e.target.checked){
                container_group_slider[0].classList.remove('ocultar');
            }else{
                container_group_slider[0].classList.add('ocultar');
                group_slider_clone[0].innerHTML = '';
            }
        })

        $('#preference_key_id').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            if ($(e.target).select2('data')[0].text.includes('social')) {
                behavior = 'social';
            } else {
                behavior = 'general';
            }

            $('#preference_behavior')[0].value = behavior;
        })

        //SECTION
        $('#section_typesection').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            let thumbnails_banner_pic = document.getElementById('thumbnail-banner-image_pic');
            let thumbnails_banner_no_pic = document.getElementById('thumbnail-banner-image_no_pic');
            let thumbnails_banner = document.getElementById('thumbnail-banner-image');
            let thumbnails = document.getElementById('thumbnail-image');
            let complementary_fields = document.getElementById('complementary_fields');

            if ($(e.target).select2('data')[0].text.includes('footer')) {
                behavior = 'footer';
                thumbnails_banner_no_pic.style.display = 'block';
                thumbnails_banner_pic.style.display = 'none';
                thumbnails_banner_pic.style.display = 'none';
            } else {
                behavior = 'body';
                thumbnails_banner_no_pic.style.display = 'none';
                thumbnails_banner_pic.style.display = 'block';
                if ($(e.target).select2('data')[0].text.includes('imagen')) {
                    // thumbnails.style.display = 'block';
                    thumbnails_banner.style.display = 'block';
                } else {
                    // thumbnails.style.display = 'none';
                    thumbnails_banner.style.display = 'block';
                }

                //CAMPO COMPLEMENTARIO
                // console.log($(e.target).select2('data')[0].text);
                // console.log(complementary_fields);
                // if($(e.target).select2('data')[0].text.includes('comerciales')){
                //     complementary_fields.style.display = 'block';
                // }else{
                //     complementary_fields.style.display = 'none';
                // }
            }




            $('#section_destination')[0].value = behavior;
        }).trigger('change')

        //PAGES
        let page_external = $('#page_isExternaUrl');
        if(page_external !== undefined && page_external !== null){

            let fields = document.getElementById('fields');
            let access_url = document.getElementById('page_access_url');
            let acces_url_value = '';
            let page_icon = document.getElementById('page_icon');
            if(access_url !== undefined && access_url !== null){
                 acces_url_value = access_url.value;
            }
            let thumbnails_ni = document.getElementById('thumbnails-no-image');
            let thumbnails_image = document.getElementById('thumbnails-image');

            page_external.on('change',function (e) {
                if($(e.target).is(':checked')){
                    access_url.value = 'https://';
                    thumbnails_ni.style.display = 'block';
                    thumbnails_image.style.display = 'none';
                }else{
                    access_url.value = acces_url_value;
                    thumbnails_ni.style.display = 'none';
                    thumbnails_image.style.display = 'block';
                }
            })
        }

        // let page_product = $('#page_product').select2({
        //     placeholder: 'Seleccione el tipo de formulario a mostrar',
        //     allowClear: true,
        //     ajax: {
        //         // url: `${location.origin}/es/admin/product_types/data`,
        //         url: `${location.origin}/es/admin/surveys/data`,
        //         dataType: "json",
        //         language: "es",
        //         theme: 'bootstrap',
        //         data: function (params) {
        //             return {
        //                 search: params.term,
        //                 page: params.page || 1
        //             }
        //
        //         },
        //         type: 'GET',
        //         processResults: function (data, params) {
        //             let result = [];
        //             params.page = params.page || 1;
        //
        //             data.rows.map ((elem, key) => {
        //                 result.push ({
        //                     id: elem.code,
        //                     text: elem.name
        //                 })
        //             })
        //
        //             if (data.total > 10) {
        //
        //                 if ((parseInt (data.total) - (10 * parseInt (params.page))) > 1) {
        //                     return {
        //                         results: result,
        //                         "pagination": {
        //                             "more": true
        //                         }
        //                     }
        //                 } else {
        //                     return {
        //                         results: result,
        //                         "pagination": {
        //                             "more": false
        //                         }
        //                     }
        //                 }
        //
        //             } else {
        //                 return {
        //                     results: result,
        //                     "pagination": {
        //                         "more": false
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // })

        $('#page_has_product').on('change',(e) =>{
            let typo = $('#select_type_producto');
            if(e.target.checked){
                typo[0].classList.remove('ocultar')
            }else{
                typo[0].classList.add('ocultar')
            }
        })

        //NOTIFICATION PUSH
        let user_check = $('#notification_push_users_notification');
        if(user_check !== undefined && user_check !== null){
            let users_select = document.getElementById('users_select');
            $('#notification_push_notify_all').on('change',function (e) {

                if($(e.target).is(':checked')){
                    users_select.style.display = 'none';
                }else{
                    users_select.style.display = 'block';
                }
            })
        }

    }

    importData(e) {

        let url_action = e.target.dataset.url;

        if(url_action === undefined){
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let url = location.origin + '/' + url_action;
        $.ajax({
            url: url,
            data: {format: 'json'},
            type: 'GET',
            success: function (data) {
                if (data.success) {
                    toastr.success(`(${data.count}) ${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`(${data.count}) ${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                }

            }
        })
    }

    setActive(e) {
        let url = location.origin + '/' + e.target.dataset.url;
        $.ajax({
            url: url,
            data: {active: e.target.dataset.active},
            type: 'POST',
            success: function (data) {
                if (data.success) {
                    toastr.success(`${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`${data.msg}`);
                }

            }
        })
    }

    setBlockActive(e) {
        let url_action = e.target.dataset.url;

        if(url_action === undefined){
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }


        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + url_action;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }
    setBlockSameHours(e) {
        let url_action = e.target.dataset.url;

        if(url_action === undefined){
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + url_action;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }

    destroyBlock(e){
        let url_action = e.target.dataset.url;
        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {

            if(url_action === undefined){
                let parent = e.target.parentNode;
                url_action = parent.dataset.url
            }

            bootbox.confirm(`¿Seguro de eliminar estos ${ids.length} elementos? `, (result) =>{
                if(result){
                    let url = location.origin + '/' + url_action;
                    $.ajax({
                        url: url,
                        data: {id: ids},
                        type: 'POST',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(`${data.msg}`);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(`${data.msg}`);
                            }

                        }
                    })
                }
            })

        }
    }

    sendValidateUserEmail(e){
        e.preventDefault();
        e.stopPropagation();
        $.ajax({
            url: e.target.dataset.url,
            data: {
                format: 'json',
            },
            type: 'GET',
            success: function (result) {
                if(result.success){
                    toastr.success(result.message,result.title);
                }else{
                    toastr.error('No se ha podido enviar el correo de validación para este usuario.', 'Error en el envio del correo');
                }
            }
        })
    }

    counter_characters(e){
        contar_caracteres(e.target.dataset.max,e.target.dataset.counter, e.target.id);
    }

    showImg(e){
        let src = document.getElementById(e.target.dataset.value).value
        bootbox.alert({
            message: `<img src='${src}' style='width: 100%'>`,
            buttons: {
                ok: {
                    label: "<span class='fa fa-check'></span> Visto",
                    className: 'btn-info'
                }
            }
        })
    }

//  METODOS PARA EL DASHBORAD

    actionWithReservationCheck(e){
        let action = e.target.dataset.act;
        let id = e.target.dataset.id;
        let table = e.target.dataset.table;
        $.ajax({
            url: `${location.origin}/admin/action_with_revision/${action}/${id}`,
            data: {},
            type: 'GET',
            success: function (data) {
                if(data.success){
                    toastr.success(data.message,'Ok');
                }else{
                    toastr.error(data.message,'Error');
                }

                $(`#${table}`).bootstrapTable('refresh')
            }
        })
    }

    checkReservationStatus(e){
        // console.log(e.target);
        let references = e.target.dataset.references;
        $.ajax({
            url: `${location.origin}/admin/check_reservation/${references}.json`,
            data: {
                format: 'json'
            },
            type: 'GET',
            beforeSend: function () {
                let element = $(`#check_reservation_${references}`);
                // console.log(element);
                element[0].innerHTML = `<span class="fa fa-spin fa-refresh" data-references="${element.id}"></span>`
            },
            success: function (data) {
                let element = $(`#check_reservation_${references}`);
                element[0].innerHTML = `<span class="fa fa-refresh" data-references="${element.id}"></span>`
                if(data.success){
                    bootbox.alert(data.message);
                }else{
                    toastr.error(data.message, 'Error');
                }
            }
        })
    }

    typeUrl(e){
        let value = e.target.value.toLowerCase().split(" ");
        value = value.join("-");
        value = value.split("_");
        e.target.value = value.join("-");
    }
}