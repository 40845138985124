import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from "./localize_controller";


export default class extends Controller {

    connect() {
        let all_locales_string = document.getElementById('all_locales').dataset.value;

        all_locales_string= all_locales_string.split('[').join('');
        all_locales_string = all_locales_string.split(':').join('');
        all_locales_string = all_locales_string.split(']').join('');

        this.all_locales = all_locales_string.split(',')
        let answer_form = $('#answer_form');
        let areas_form = $('[id*="input-area-"]');
        let select_type_answer = $('select[name*="type_answer"]')
        if(location.href.includes('edit')){
            setTimeout(()=>{
                select_type_answer.map(elem => {
                   $(select_type_answer[elem]).trigger('change');
                })
            },1000);

        }


       $('#admin_question_survey_type_answer').select2({
           theme: "bootstrap",
           language: "es",
           placeholder: "Seleccione una opción",
           allowClear: true
       }).on('change',(e)=> {

           if(e.target.value === 'textarea'){
               answer_form[0].classList.add('ocultar');
               if(areas_form.length > 0){
                   areas_form.map(index=> {

                       areas_form[index].innerHTML = '';
                   })
               }
           }else{
               answer_form[0].classList.remove('ocultar');
           }
       })

        if(location.href.includes('edit')) {
            select_type_answer.select2({
                theme: "bootstrap",
                language: "es",
                placeholder: "Seleccione una opción",
                allowClear: true
            }).on('change', (e) => {

                console.log(e.target.dataset);

                let answer_form_selected = $(`#${e.target.dataset.area}`);
                let area_form_selected = $(`#${e.target.dataset.fields}`);


                if (e.target.value === 'textarea') {
                    answer_form_selected[0].classList.add('ocultar');
                    if (area_form_selected.length > 0) {
                        area_form_selected.map(index => {

                            area_form_selected[index].innerHTML = '';
                        })
                    }
                } else {
                    answer_form_selected[0].classList.remove('ocultar');
                }
            })
        }
    }

    clone(e){

        this.all_locales.map(lang => {
            let target = $(`#${e.target.dataset.clone}-${lang.trim()}`);
            let html = '';
            setTimeout(()=>{
                if(e.target.dataset.hasOwnProperty('edit')){
                    let edit = e.target.dataset.edit;
                     html = `<div class="row" id="answer_question_${target.find('.evaluate').length}_${lang.trim()}">
                                    <div  class="col-6 mt-3">
                                     <input type="hidden" 
                                        class="form-control" 
                                        name="admin_question_survey[admin_answer_question_surveys][${edit}][][id]" 
                                        value="0" >
                     
                                        <input type="text"
                                               class="form-control evaluate"
                                               name="admin_question_survey[admin_answer_question_surveys][${edit}][][answers_${lang.trim()}]"
                                               placeholder="Respuesta ${I18n.t('global.admin.' + lang.trim())} #${parseInt(target.find('.evaluate').length) + 1}"
                                        >
                                    </div>
                                    <div class="col-1 mt-3">
                                          <input class="form-control answer_priority_${target.find('.evaluate').length}" type="number" value="${target.find('.evaluate').length + 1}" placeholder="Prioridad" id="number${target.find('.evaluate').length}" name="admin_question_survey[admin_answer_question_surveys][${edit}][][priority]" data-action="surveys#setPriority" data-target="answer_priority_${target.find('.evaluate').length}"> 
                                    </div>
                                    <div class="col-4 mt-3">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input answer_evaluate_${target.find('.evaluate').length}" type="checkbox" id="inlineCheckbox1${target.find('.evaluate').length}" name="admin_question_survey[admin_answer_question_surveys][${edit}][][use_for_acceptation]"  data-action="surveys#checkRadio" data-target="answer_evaluate_${target.find('.evaluate').length}">
                                          <label class="form-check-label" for="inlineCheckbox1${target.find('.evaluate').length}">Evaluar para porciento de aceptación</label>
                                        </div>
                                    </div>
                                    <div class="col-1 mt-3">
                                        <button type="button" data-action="surveys#remove" data-target="answer_question_${target.find('.evaluate').length}" class="btn btn-danger">
                                            <span class="fa fa-trash" data-target="answer_question_${target.find('.evaluate').length}"></span>
                                        </button>
                                    </div>
                                </div>`;

                }else{
                     html = `<div class="row" id="answer_question_${target.find('.evaluate').length}_${lang.trim()}">
                                    <div  class="col-6 mt-3">
                                        <input type="text"
                                               class="form-control evaluate"
                                               name="admin_question_survey[admin_answer_question_surveys][][answers_${lang.trim()}_${target.find('.evaluate').length}]"
                                               placeholder="Respuesta ${I18n.t('global.admin.'+lang.trim())} #${parseInt(target.find('.evaluate').length) + 1}"
                                        >
                                    </div>
                                    <div class="col-1 mt-3">
                                          <input class="form-control answer_priority_${target.find('.evaluate').length}" type="number" value="${target.find('.evaluate').length + 1}" placeholder="Prioridad" id="number${target.find('.evaluate').length}" name="admin_question_survey[admin_answer_question_surveys][][answers_${lang.trim()}_${target.find('.evaluate').length}]" data-action="surveys#setPriority" data-target="answer_priority_${target.find('.evaluate').length}">
                                        
                                    </div>
                                    <div class="col-4 mt-3">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input answer_evaluate_${target.find('.evaluate').length}" type="checkbox" id="inlineCheckbox1${target.find('.evaluate').length}" name="admin_question_survey[admin_answer_question_surveys][][answers_${lang.trim()}_${target.find('.evaluate').length}]" data-action="surveys#checkRadio" data-target="answer_evaluate_${target.find('.evaluate').length}">
                                          <label class="form-check-label" for="inlineCheckbox1${target.find('.evaluate').length}">Evaluar para porciento de aceptación</label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-1 mt-3">
                                        <button type="button" data-action="surveys#remove" data-target="answer_question_${target.find('.evaluate').length}" class="btn btn-danger">
                                            <span class="fa fa-trash" data-target="answer_question_${target.find('.evaluate').length}"></span>
                                        </button>
                                    </div>
                                    
                                </div>`;

                }
                target.append(html);
            },500)
        })

    }

    checkRadio(e){
        let target = $(e.target);
        let dataset = target[0].dataset.target;
        $(`.${dataset}`).prop('checked',target.is(':checked'));
    }

    setPriority(e){
        let target = $(e.target);
        let dataset = target[0].dataset.target;
        $(`.${dataset}`).map((index, elem) => {
            elem.value = target[0].value;
        });
    }

    remove(e){
        console.log(this.all_locales);
        this.all_locales.map(lang => {
            let target =  $(`#${e.target.dataset.target}_${lang.trim()}`);
            console.log(target)
            target.fadeOut('slow').delay(1000).remove();
         });
    }

    deleteQuestion(e){
        let id = e.target.dataset.id;
         let element = $(`#question_${id}`);
        console.log(id);
        console.log(element);
        $.ajax({
            url: `${location.origin}/admin/surveys/question/${id}/delete`,
            data: {},
            type: 'GET',
            beforeSend: function () {
                toastr.info('Eliminando pregunta de la encuesta...');
            },
            success: function (data) {
                if(data.success){
                    toastr.success(data.msg);
                    element.remove();
                }else{
                    toastr.error(data.msg);
                }
            }
        })
    }
}