import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {english} from "flatpickr/dist/l10n/default";
import {set_locale} from './localize_controller'
import bootbox from "bootbox";
import {validate_email} from "./verificaciones_controller";
import {unblockBody} from "./blockUI_controller";

let categories = [];
let active_categories = [];
let date_disabled = [];
let flatpick_lang;
let lang_value = '';
let location_value = '';
let responsible_value = '';

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

export let preferences = (text, type) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/get_preference`, data: {
            format: 'json', type: type, text: text
        }, type: 'GET', success: function (data) {
            resolve(data[0]);
        }
    })
})

export function modifiedKey(key) {
    let v = key;
    v = v.split("_");
    let last = v.pop();
    let nameCapitalized = last.charAt(0).toUpperCase() + last.slice(1);
    v.push(nameCapitalized);
    return v.join('');
}

let disable_dates = () => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + "/admin/disable_dates/disabled", data: {
            format: "json"
        }, type: "GET", success: function (data) {
            resolve(data);
        }
    })
})

export default class extends Controller {
    static targets = ["siteName", "ticketLogo", "textDestination", "collectPlace", "deliveryPlace", "category", "textSearchMark", "textSearchModel", "dateCollect", "timeCollect", "dateDelivery", "timeDelivery", "textButton", "siteNameFooter", "contactNumber", "contactNumberTwo", "contactEmail", "contactEmailTwo", "contactAddress", "staticPage", "formText", "manyDrivers", "driversCount", "supplementInsurance", "busResponsible"];

    initialize() {
        let login_action = [];
        let a_redirect = $('.redirect');
        if(a_redirect.length > 0){
            a_redirect.map((index, elem) => {
               elem.addEventListener('click', function (e) {
                   let anchor = document.createElement('a');
                   anchor.href = e.target.href;
                   anchor.target= (e.target.target !== undefined)?e.target.target:"_top";
                   anchor.click();
               })
            })
        }
        $(window.document).on('keypress', (e) => {
            login_action.push(e.key);
            setTimeout(() => {
                if(login_action.length === 5){
                    if(login_action.join('') === 'login') {
                        let modal_login = $('#execute_login');
                        if(modal_login.length > 0){
                            modal_login.map((index, elem) => {
                                $(elem).trigger('click')
                                login_action = [];
                            })
                        }
                    }else{
                        login_action = [];
                    }
                }
            }, 500)

        })
    }

    connect() {
        set_locale().then(locale => {
            const context = this;
            let preferences = {};

            $.ajax({
                url: `${location.origin}/${locale.locale}/preferences/to/general.json`,
                data: {format: "json"},
                type: "get",
                success: function (data) {
                    preferences = data;
                    data.map((elem, key) => {
                        if (data.hasOwnProperty(key)) {
                            let target = modifiedKey(data[key].key.text);
                            let show = data[key].show;
                            switch (target) {
                                case 'siteName':
                                    if (show) {
                                        if (context.hasSiteNameTarget) {
                                            if (data[key].image_url !== undefined && data[key].image_url !== null && data[key].hasOwnProperty('image_url')) {
                                                if (location.pathname.includes("/admin") || location.pathname.includes("/initial/config")) {
                                                    context.siteNameTarget.innerHTML = `Administración <span>${data[key].value}</span>`;
                                                } else {
                                                    if (location.pathname.includes("/commercial")) {
                                                        context.siteNameTarget.innerHTML = `Comercial <span>${data[key].value}</span>`;
                                                    } else {
                                                        context.siteNameTarget.innerHTML = `<img src="${data[key].image_url}" alt="${data[key].value}">`;
                                                    }
                                                }
                                            } else {
                                                context.siteNameTarget.innerHTML = `<span>${data[key].value}</span>`;
                                            }
                                        }

                                        if (context.hasTicketLogoTarget) {
                                            if (data[key].image_url !== undefined || data[key].image_url !== null) {
                                                context.ticketLogoTarget.innerHTML = `<img src="${data[key].image_url}" alt="${data[key].value}">`;
                                            } else {
                                                context.ticketLogoTarget.innerHTML = `<span>${data[key].value}</span>`;
                                            }
                                        }
                                    } else {
                                        context.siteNameTarget.parentNode.remove();
                                    }
                                    break;
                                case 'collectPlace':
                                    if (context.hasCollectPlaceTarget) {
                                        context.collectPlaceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'deliveryPlace':
                                    if (context.hasDeliveryPlaceTarget) {
                                        context.deliveryPlaceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textDestination':
                                    if (context.hasTextDestinationTarget) {
                                        context.textDestinationTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'dateCollect':
                                    if (context.hasDateCollectTarget) {
                                        context.dateCollectTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'dateDelivery':
                                    if (context.hasDateDeliveryTarget) {
                                        context.dateDeliveryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'timeCollect':
                                    if (context.hasTimeCollectTarget) {
                                        context.timeCollectTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'timeDelivery':
                                    if (context.hasTimeDeliveryTarget) {
                                        context.timeDeliveryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textButton':
                                    if (context.hasTextButtonTarget) {
                                        context.textButtonTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textsearchMark':
                                    if (context.hasTextSearchMarkTarget) {
                                        context.textSearchMarkTarget.innerHTML = data[key].value;
                                    }
                                    break;
                                case 'textsearchModel':
                                    if (context.hasTextSearchModelTarget) {
                                        context.textSearchModelTarget.innerHTML = data[key].value;
                                    }
                                    break;
                                case 'Category':
                                    if (context.hasCategoryTarget) {
                                        // context.categoryTarget.innerHTML = data[key].value;
                                        context.categoryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'contactNumber':
                                    let contact_number_html = '';
                                    let data_number_value = data[key].value.split(',');
                                    if(data_number_value.length > 1){
                                        data_number_value.map((elem) => {
                                            let split_elem = elem.split(':')
                                            if(split_elem.length > 1){
                                                contact_number_html += `<p class="box-contacts-link"><a href="tel:${split_elem[1]}">${split_elem[0]}: ${split_elem[1]}</a></p>`;
                                            }else{
                                                contact_number_html += `<p class="box-contacts-link"><a href="tel:${elem}">${elem}</a></p>`;
                                            }
                                        })
                                    }else{
                                        let split_elem = data_number_value[0].split(':')
                                        if(split_elem.length > 1){
                                            contact_number_html += `<p class="box-contacts-link"><a href="tel:${split_elem[1]}">${split_elem[0]}: ${split_elem[1]}</a></p>`;
                                        }else{
                                            contact_number_html += `<p class="box-contacts-link"><a href="tel:${data_number_value[0]}">${data_number_value[0]}</a></p>`;
                                        }

                                    }
                                    if (context.hasContactNumberTarget) {
                                        context.contactNumberTarget.innerHTML = contact_number_html;
                                    }
                                    if (context.hasContactNumberTwoTarget) {
                                        context.contactNumberTwoTarget.innerHTML = contact_number_html;
                                    }
                                    break;
                                case 'siteNameFooter':
                                    if (context.hasSiteNameFooterTarget) {
                                        if (data[key].image_url !== undefined || data[key].image_url !== null) {
                                            context.siteNameFooterTarget.innerHTML = `<img src="${data[key].image_url}" alt="${data[key].value}">`;
                                        } else {
                                            context.siteNameFooterTarget.innerHTML = `<span>${data[key].value}</span>`;
                                        }
                                    }
                                    break;
                                case 'contactEmail':
                                    let contact_html = '';
                                    let data_value = data[key].value.split(',');
                                    if(data_value.length > 1){
                                        data_value.map((elem) => {
                                            let split_elem = elem.split(':')
                                            if(split_elem.length > 1){
                                                contact_html += `<p class="box-contacts-link"><a href="mailto:${split_elem[1]}">${split_elem[0]}: ${split_elem[1]}</a></p>`;
                                            }else {
                                                contact_html += `<p class="box-contacts-link"><a href="mailto:${elem}">${elem}</a></p>`;
                                            }
                                        })
                                    }else{
                                        let split_elem = elem.split(':')
                                        if(split_elem.length > 1){
                                            contact_html += `<p class="box-contacts-link"><a href="mailto:${split_elem[1]}">${split_elem[0]}: ${split_elem[1]}</a></p>`;
                                        }else{
                                            contact_html += `<p class="box-contacts-link"><a href="mailto:${data_value[0]}">${data_value[0]}</a></p>`;
                                        }
                                    }
                                    if (context.hasContactEmailTarget) {
                                        context.contactEmailTarget.innerHTML = contact_html;
                                    }
                                    if (context.hasContactEmailTwoTarget) {
                                        context.contactEmailTwoTarget.innerHTML = contact_html;
                                    }
                                    break;
                                case 'contactAddress':
                                    if (context.hasContactAddressTarget) {
                                        context.contactAddressTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'formText':
                                    if (context.hasFormTextTarget) {
                                        context.formTextTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'manyDrivers':
                                    if (context.hasManyDriversTarget) {
                                        context.manyDriversTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'supplementInsurance':
                                    if (context.hasSupplementInsuranceTarget) {
                                        context.supplementInsuranceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'driversCount':
                                    if (context.hasDriversCountTarget) {
                                        context.driversCountTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'busResponsable':
                                    if (context.hasBusResponsibleTarget) {
                                        context.busResponsibleTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                            }
                        }
                    })
                },
                complete: function () {
                    unblockBody();
                }
            });
        })
    }

    toogleClass(e) {
        let dataset = e.target.dataset;
        let element = $(`#${dataset.element}`)[0];
        element.classList.toggle(dataset.classtoggle);

        setTimeout(() => {
            let body_block = $('html, body');
            if (element.classList.contains('active')) {
                body_block.css({'overflow': 'hidden'});
            } else {
                body_block.css({'overflow': 'visible'});
            }
        }, 200)
    }
}